@import "~tailwindcss/base.css";
@import "~tailwindcss/components.css";
@import "~tailwindcss/utilities.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  background: #e5e5e5;
  color: #5e6977;
  font-size: 14px;
  line-height: 16px;
  min-width: 1200px;
  font-family: Inter, -apple-system, SF Pro Display, BlinkMacSystemFont, Segoe UI, Roboto,
  Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, sans-serif;
;
}

.btn {
  @apply inline-flex items-center justify-center border text-sm cursor-pointer shadow-none rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}

.btn:disabled {
  @apply bg-gray-300 text-white border-gray-300 cursor-not-allowed
}
.btn:hover:disabled {
  @apply bg-gray-300 text-white border-gray-300 cursor-not-allowed
}

.btn-size-md {
  @apply py-2 px-4
}

.btn-primary {
  @apply border-transparent text-white bg-indigo-600 hover:bg-indigo-700
}

.btn-secondary {
  @apply border-gray-300 text-gray-700 bg-white hover:bg-gray-50
}

.btn-danger {
  @apply border-transparent text-white bg-red-600 hover:bg-red-700
}

.btn-success {
  @apply border-transparent text-white bg-green-600 hover:bg-green-700
}

/*----------INPUT----------*/
.input {
  @apply text-gray-400 border border-gray-300 border-solid rounded-md py-2 px-4 bg-white text-sm leading-4 focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none
}
.input:disabled {
  cursor: not-allowed;
}

/*----------TEXTAREA----------*/
.textarea {
  @apply text-gray-500 border border-gray-300 border-solid rounded-md py-2 px-4 bg-white outline-none resize-none
}
.textarea:disabled {
  cursor: not-allowed;
}
